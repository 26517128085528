<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-form ref="form" :disabled="!isAdmin">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-text>
          <validation-observer ref="observer">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Truck Number" rules="required">
                    <v-text-field
                      v-model="item.id"
                      label="Truck Number"
                      prepend-icon="mdi-pound"
                      :disabled="edit"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Truck Status" rules="required">
                    <data-selector
                      v-model="item.statusId"
                      label="Status"
                      database-module="truckStatus"
                      getter="truckStatus"
                      item-text="statusText"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Truck Make" rules="required">
                    <v-text-field
                      v-model="item.make"
                      label="Make"
                      prepend-icon="mdi-truck"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Truck Model" rules="required">
                    <v-text-field
                      v-model="item.model"
                      label="Model"
                      prepend-icon="mdi-truck-cargo-container"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Truck Year" rules="required|numeric">
                    <v-text-field
                      v-model="item.year"
                      label="Year"
                      prepend-icon="mdi-calendar"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Truck Color" rules="required">
                    <v-text-field
                      v-model="item.color"
                      label="Color"
                      prepend-icon="mdi-palette"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Truck VIN" rules="required">
                    <v-text-field
                      v-model="item.vin"
                      label="VIN"
                      prepend-icon="mdi-numeric-1-box"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Truck Plates" rules="required">
                    <v-text-field
                      v-model="item.plates"
                      label="Plates"
                      prepend-icon="mdi-numeric-1-box-outline"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model.trim="item.note" auto-grow label="Note" rows="4" row-height="30" clearable>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <edit-actions @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import crudEdit from '../../mixins/crudEdit'
// import DataSelector from './_DataSelector.vue'
import DataSelector from '../MDataSelector.vue'
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { numeric } from 'vee-validate/dist/rules'

extend('numeric', {
  ...numeric,
  message: 'A number is required',
})

export default {
  components: {
    DataSelector,
    EditHeader,
    EditActions,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [crudEdit],
  data() {
    return {
      defaultItem: {
        id: '',
        statusId: '',
        make: '',
        model: '',
        year: '',
        color: '',
        vin: '',
        plates: '',
      },
    }
  },
}
</script>
<style scoped>
@import '../../assets/styles/crudDialog.css';
</style>
