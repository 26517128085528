import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form",attrs:{"disabled":!_vm.isAdmin}},[_c(VCard,[_c('edit-header',{attrs:{"dialog-title":_vm.dialogTitle}}),_c(VCardText,[_c('validation-observer',{ref:"observer"},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Trailer Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Trailer Number","prepend-icon":"mdi-pound","disabled":_vm.edit,"error-messages":errors},model:{value:(_vm.item.id),callback:function ($$v) {_vm.$set(_vm.item, "id", $$v)},expression:"item.id"}})]}}])})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('data-selector',{attrs:{"label":"Status","database-module":"trailerStatus","getter":"trailerStatus","item-text":"statusText","item-value":"id","not-empty":true,"data":_vm.item.statusId},on:{"update:data":function($event){return _vm.$set(_vm.item, "statusId", $event)}}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Trailer Make","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Make","prepend-icon":"mdi-truck","error-messages":errors},model:{value:(_vm.item.make),callback:function ($$v) {_vm.$set(_vm.item, "make", $$v)},expression:"item.make"}})]}}])})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Trailer Model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Model","prepend-icon":"mdi-truck-cargo-container","error-messages":errors},model:{value:(_vm.item.model),callback:function ($$v) {_vm.$set(_vm.item, "model", $$v)},expression:"item.model"}})]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Trailer Year","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Year","prepend-icon":"mdi-calendar","error-messages":errors},model:{value:(_vm.item.year),callback:function ($$v) {_vm.$set(_vm.item, "year", $$v)},expression:"item.year"}})]}}])})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Trailer Plates","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Plates","prepend-icon":"mdi-numeric-1-box-outline","error-messages":errors},model:{value:(_vm.item.plates),callback:function ($$v) {_vm.$set(_vm.item, "plates", $$v)},expression:"item.plates"}})]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCheckbox,{attrs:{"label":"Is small Trailer"},model:{value:(_vm.item.smallTrailer),callback:function ($$v) {_vm.$set(_vm.item, "smallTrailer", $$v)},expression:"item.smallTrailer"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"auto-grow":"","label":"Note","rows":"4","row-height":"30","clearable":""},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.note"}})],1)],1)],1)],1)],1),_c(VDivider),_c('edit-actions',{on:{"exit":_vm.exit,"submit":_vm.onSubmit}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }